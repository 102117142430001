"use client";

import { useAtom } from "jotai";
import { Checkbox } from "#/ui/standard-checkbox";
import { Label } from "#/ui/label";
import { useTStream } from "#/hooks/use-t-stream";
import { atoms } from "#/lib/atoms/atoms";

export const SaveLocationsCheckmark = () => {
  const { t } = useTStream("common");
  const [applyToAll, setApplyToAll] = useAtom(atoms.applyAllLocationsChanges);

  return (
    <div className="flex items-center space-x-2 py-1">
      <Checkbox
        id="apply-to-all-locations"
        checked={applyToAll}
        onChange={(e) => setApplyToAll(e.target.checked)}
        className="h-3.8 w-3.8"
      />
      <Label
        htmlFor="apply-to-all-locations"
        className="text-xs text-muted cursor-pointer"
      >
        {t("Apply to all locations")}
      </Label>
    </div>
  );
};
