import { useTStream } from "#/hooks/use-t-stream";
import { cn } from "#/lib/utils";
import { Button } from "#/ui/button";
import { Building } from "lucide-react";
import { useIntercom } from "react-use-intercom";

export const EnterpriseSection = ({ className }: { className?: string }) => {
  const { t } = useTStream("common");
  const { show } = useIntercom();
  return (
    <div
      className={cn(
        "flex rounded-xl border-[1px] p-3 border-border items-center gap-2",
        className,
      )}
    >
      <div className="rounded-full bg-emerald-50 p-2">
        <Building className="h-5 w-5 text-emerald-500" />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex items-center justify-between w-full">
          <div>
            <h4 className="font-semibold">{t("Enterprise")}</h4>
            <p className="text-sm text-muted">
              {t("Have more than 100 locations?")}
            </p>
          </div>
          <Button className="ml-auto" variant="outline" onClick={() => show()}>
            {t("Contact us")}
          </Button>
        </div>
      </div>
    </div>
  );
};
