import { ConfirmDialogStacked } from "#/components/confirm-dialog";
import { SubscriptionModal } from "#/components/modals/subscription/subscription-modal";
import { SaveLocationsCheckmark } from "#/components/save-locations-checkmark";
import { useMenuSchedules } from "#/hooks/data/menu/use-menu-schedules";
import { useTStream } from "#/hooks/use-t-stream";
import { atoms } from "#/lib/atoms/atoms";
import { mutations } from "#/lib/atoms/mutations";
import { cn } from "#/lib/utils";
import { ThemeContext } from "#/providers/theme-provider";
import { components } from "#/types.ts/swagger";
import { Badge } from "#/ui/badge";
import { Button } from "#/ui/button";
import { DateTimePickerForm } from "#/ui/date-time-picker-form";
import { Label } from "#/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "#/ui/popover";
import { RadioGroup, RadioGroupItem } from "#/ui/radio-group";
import { Separator } from "#/ui/separator";
import { SnoozeTypes } from "#/utils/menu.utils";
import { determineNextOpen } from "#/utils/schedule.utils";
import { SUBSCRIPTION_TIERS } from "#/utils/subscription.utils";
import { MoonIcon } from "@heroicons/react/24/outline";
import { MoonIcon as MoonIconSolid } from "@heroicons/react/24/solid";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useContext, useMemo, useState } from "react";

export const MenuItemSnoozePopover = ({
  id,
  type,
  items,
  scheduled_is_active,
  showText = true,
  pos_is_active,
}: {
  id: string;
  showText?: boolean;
  pos_is_active: boolean;
  type: "item_family" | "item";
  items?: components["schemas"]["AssembledItem"][];
  scheduled_is_active: components["schemas"]["ScheduledIsActive"] | null;
}) => {
  const { t } = useTStream("common");
  const [open, setOpen] = useState(false);
  const applyToAllLocations = useAtomValue(atoms.applyAllLocationsChanges);

  const stopPropogation = useCallback((e) => e.stopPropagation(), []);
  const theme = useContext(ThemeContext);
  const { currentMenuSchedule: schedule } = useMenuSchedules();
  const [snoozeDate, setSnoozeDate] = useState<Date | undefined>(() => {
    if (scheduled_is_active?.scheduled_for) {
      return new Date(scheduled_is_active.scheduled_for);
    }
    const snoozedItem = items?.find(
      (item) => item.scheduled_is_active?.scheduled_for,
    );
    if (snoozedItem?.scheduled_is_active?.scheduled_for) {
      return new Date(snoozedItem.scheduled_is_active.scheduled_for);
    }
    return undefined;
  });

  const isSnoozed = useMemo(() => {
    return (
      scheduled_is_active?.scheduled_for ||
      items?.find((item) => item.scheduled_is_active?.scheduled_for)
    );
  }, [items, scheduled_is_active, snoozeDate]);

  const [resetting, setResetting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snoozeType, setSnoozeType] = useState<string>(SnoozeTypes.custom);
  const [{ mutateAsync: mutate }] = useAtom(mutations.catalogUpdate);

  const onOpenChange = useCallback((open: boolean) => {
    setOpen(open);
    if (!open) {
      setSnoozeDate(undefined);
    }
  }, []);

  const onChangeType = useCallback(
    (type: string) => {
      if (type === SnoozeTypes.custom) {
        const date = new Date();
        date.setHours(date.getHours() + 1, date.getMinutes(), 0, 0);
        setSnoozeDate(date);
      }
      if (type === SnoozeTypes.one_week) {
        const date = new Date();
        date.setDate(date.getDate() + 7);
        date.setHours(date.getHours() + 1, date.getMinutes(), 0, 0);
        setSnoozeDate(date);
      }

      if (type === SnoozeTypes.until_next_open) {
        setSnoozeDate(determineNextOpen(schedule?.schedule));
      }
      setSnoozeType(type);
    },
    [setSnoozeType, schedule],
  );

  const onSave = useCallback(
    async (e) => {
      stopPropogation(e);
      setLoading(true);
      await mutate({
        flag: applyToAllLocations ? "all_locations" : "specific_locations",
        [type]: [
          {
            _id: id,
            /** set to inactive right now */
            is_active: false,
            /** schedule the restoration of item to active */
            scheduled_is_active: {
              is_active: true,
              scheduled_for: snoozeDate.getTime(),
            },
          },
        ],
      });
      setLoading(false);
      setOpen(false);
    },
    [
      snoozeDate,
      applyToAllLocations,
      type,
      id,
      mutate,
      setLoading,
      setOpen,
      stopPropogation,
    ],
  );

  const onRevert = useCallback(
    async (e) => {
      setResetting(true);
      stopPropogation(e);

      await mutate({
        flag: applyToAllLocations ? "all_locations" : "specific_locations",
        [type]: [
          {
            _id: id,
            is_active: pos_is_active,
            scheduled_is_active: null,
          },
        ],
      });
      setResetting(false);
      setSnoozeDate(undefined);
      setOpen(false);
    },
    [id, mutate, type, items, setResetting, setOpen],
  );

  const onUpdateDate = useCallback((date: Date) => {
    setSnoozeDate(date);
  }, []);

  if (!theme?.flags?.snoozeEnabled) return null;
  return (
    <Popover modal onOpenChange={onOpenChange} open={open}>
      <SubscriptionModal
        paywall="snooze"
        component={
          <button
            onClick={stopPropogation}
            data-testid="item-snooze-popover-button"
            className="flex items-center gap-2"
          >
            <MoonIconSolid className="text-indigo-400 mr-2 cursor-pointer h-4 w-4 transition-colors duration-200 hover:text-indigo-300 glow-indigo-500" />
            {showText && (
              <p className="text-indigo-400/80 text-sm transition-colors duration-200 hover:text-indigo-300/80">
                {t("Snooze")}
              </p>
            )}
          </button>
        }
        tierPermissions={[SUBSCRIPTION_TIERS.per_month_location_premium]}
      >
        <PopoverTrigger
          onClick={stopPropogation}
          id="item-snooze-popover-button"
          className="flex items-center"
        >
          {!isSnoozed ? (
            <>
              <MoonIcon className="text-muted mr-2 cursor-pointer h-4 w-4" />
              {showText && <p className="text-muted text-sm">{t("Snooze")}</p>}
            </>
          ) : (
            <Badge
              variant="secondary"
              className="bg-yellow-100 text-yellow-600"
            >
              <MoonIcon
                className={cn("h-3 w-3 text-yellow-600 text-yellow-600", {
                  "mr-2": showText,
                })}
              />
              {showText && (
                <div className="text-left">
                  <p className="text-[11px]">{t("Snoozed")}</p>
                  <p className="text-[10px] truncate">
                    {new Date(
                      snoozeDate ||
                        items?.[0]?.scheduled_is_active?.scheduled_for,
                    ).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </p>
                </div>
              )}
            </Badge>
          )}
        </PopoverTrigger>
      </SubscriptionModal>
      <PopoverContent className="w-[24rem]">
        <SnoozePopoverView
          snoozeType={snoozeType}
          onChangeType={onChangeType}
          onUpdateDate={onUpdateDate}
          onRevert={onRevert}
          onSave={onSave}
          snoozeDate={snoozeDate}
          resetting={resetting}
          loading={loading}
        />
      </PopoverContent>
    </Popover>
  );
};

export const SnoozePopoverView = ({
  snoozeType,
  onChangeType,
  onUpdateDate,
  onRevert,
  onSave,
  snoozeDate,
  resetting,
  loading,
}: {
  snoozeType: string;
  onChangeType: (type: string) => void;
  onUpdateDate: (date: Date) => void;
  onRevert: (e?: any) => void;
  onSave: (e?: any) => void;
  snoozeDate: Date;
  resetting?: boolean;
  loading?: boolean;
}) => {
  const { t } = useTStream("common");
  const stopPropogation = useCallback((e) => e.stopPropagation(), []);

  return (
    <div className="w-[22rem] pb-2 space-y-4">
      <div>
        <div className="flex items-center justify-between">
          <p className="text-foreground text-base font-500">
            {t("Snooze item")}
          </p>
          <SaveLocationsCheckmark />
        </div>

        <p className="text-muted mt-1 text-sm">
          {t("Temporarily set item as unavailable")}
        </p>
      </div>

      <RadioGroup
        value={snoozeType}
        className="space-y-3"
        onClick={stopPropogation}
        onValueChange={onChangeType}
      >
        <Separator />
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value={SnoozeTypes.one_week}
            id={SnoozeTypes.one_week}
          />
          <Label htmlFor={SnoozeTypes.one_week}>
            {t("Set unavailable for 1 week")}
          </Label>
        </div>
        <Separator />
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value={SnoozeTypes.until_next_open}
            id={SnoozeTypes.until_next_open}
          />
          <Label htmlFor={SnoozeTypes.until_next_open}>
            {t("Set unavailable until next open")}
          </Label>
        </div>
        <Separator />
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={SnoozeTypes.custom} id={SnoozeTypes.custom} />
          <Label htmlFor={SnoozeTypes.custom}>{t("Custom date & time")}</Label>
        </div>
        <DateTimePickerForm date={snoozeDate} onUpdate={onUpdateDate} />
      </RadioGroup>

      <Separator />
      <div className="space-x-2 flex items-center">
        <ConfirmDialogStacked
          onConfirm={onRevert}
          title={t(
            "Are you sure you want to revert this item's snooze status?",
          )}
          description={t("This will make the item available immediately")}
          trigger={
            <Button
              variant="outline-muted"
              size="md"
              className="w-full"
              loading={resetting}
            >
              {t("Revert")}
            </Button>
          }
        />

        <ConfirmDialogStacked
          onConfirm={onSave}
          title={t("Are you sure you want to mark this item as unavailable?")}
          description={`${t(
            "This item will be snoozed until",
          )} ${snoozeDate?.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
          })}・${snoozeDate?.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}`}
          trigger={
            <Button
              size="md"
              className="w-full"
              loading={loading}
              disabled={!snoozeDate}
            >
              {t("Apply")}
            </Button>
          }
        />
      </div>
    </div>
  );
};
