import { atoms } from "#/lib/atoms/atoms";
import { mutations } from "#/lib/atoms/mutations";
import { queries } from "#/lib/atoms/queries";

import { components } from "#/types.ts/swagger";
import { checkIfScheduleIsEmpty } from "#/utils/schedule.utils";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";

export function useMenuSchedules() {
  const currentMenuId = useAtomValue(atoms.currentMenuId);
  const currentLocationId = useAtomValue(atoms.currentLocationId);
  const [{ data: menuSchedulesResponse }] = useAtom(queries.menuSchedules);
  const [{ mutateAsync: mutate, isPending: creating }] = useAtom(
    mutations.createMenuSchedule,
  );
  const [{ mutateAsync: mutateBulk, isPending: creatingBulk }] = useAtom(
    mutations.createMenuScheduleBulk,
  );
  const [{ mutateAsync: validate, isPending: validating }] = useAtom(
    mutations.validateMenuSchedule,
  );

  const [areMenusScheduled, setAreMenusScheduled] = useState(false);

  const checkSchedulesValidity = useCallback(() => {
    if (!menuSchedulesResponse?.schedules) {
      setAreMenusScheduled(false);
      return;
    }

    let anyMenusScheduled = false;
    for (const menuSchedule of menuSchedulesResponse.schedules) {
      if (!checkIfScheduleIsEmpty(menuSchedule.schedule)) {
        anyMenusScheduled = true;
        break;
      }
    }

    setAreMenusScheduled(anyMenusScheduled);
  }, [menuSchedulesResponse]);

  useEffect(() => {
    checkSchedulesValidity();
  }, [menuSchedulesResponse]);

  return {
    creating,
    validating,
    creatingBulk,
    areMenusScheduled,
    currentMenuSchedule: useMemo(
      () =>
        menuSchedulesResponse?.schedules?.find(
          (schedule) => schedule.menu_id === currentMenuId,
        ),
      [menuSchedulesResponse?.schedules, currentMenuId],
    ),
    schedule_exceptions: menuSchedulesResponse?.schedule_exceptions || [],
    /** @ts-ignore */
    menuSchedules: (menuSchedulesResponse?.schedules ||
      []) as components["schemas"]["MenuLocationSchedule"][],
    createMenuSchedule: (data, should_adjust_for_prep_time: boolean) =>
      mutate({
        _id: currentLocationId,
        should_adjust_for_prep_time,
        menuSchedules: data,
      }),
    validateMenuSchedule: (data) =>
      validate({ _id: currentLocationId, menuSchedules: data }),
    createMenuScheduleBulk: (
      data: components["schemas"]["CreateSchedulesForLocationsDto"],
    ) => mutateBulk(data),
  };
}
