import { components } from "#/types.ts/swagger";
import {
  Day,
  IDayTimeMap,
  SchedulesByMenuId,
} from "../types.ts/schedules.types";

export const formatSelectedDays = (
  currentSchedule: components["schemas"]["MenuLocationSchedule"],
  usePosSchedule?: boolean,
) => {
  const schedule = usePosSchedule
    ? currentSchedule?.pos_schedule
    : currentSchedule?.schedule;
  if (!schedule) return [];

  const dayKeys = Object.keys(schedule) as Day[];
  const days: Day[] = [];
  dayKeys.forEach((d: Day) => {
    if (
      schedule[d] &&
      schedule[d][0] &&
      (schedule[d][0] as any).includes("-")
    ) {
      days.push(d);
    }
  });

  return days;
};

const getDateForTime = (timeString: string): Date => {
  const [hours, minutes] = timeString.split(":").map(Number);
  const now = new Date();
  now.setHours(hours || 0, minutes || 0, 0, 0); // Set time to 00:00 if no time is provided
  return now;
};

export const formatDayTimeMap = (currentSchedule): IDayTimeMap => {
  if (!currentSchedule || !currentSchedule?.schedule) {
    return {
      monday: [
        { start: getDateForTime("00:00"), end: getDateForTime("00:00") },
      ],
      tuesday: [
        { start: getDateForTime("00:00"), end: getDateForTime("00:00") },
      ],
      wednesday: [
        { start: getDateForTime("00:00"), end: getDateForTime("00:00") },
      ],
      thursday: [
        { start: getDateForTime("00:00"), end: getDateForTime("00:00") },
      ],
      friday: [
        { start: getDateForTime("00:00"), end: getDateForTime("00:00") },
      ],
      saturday: [
        { start: getDateForTime("00:00"), end: getDateForTime("00:00") },
      ],
      sunday: [
        { start: getDateForTime("00:00"), end: getDateForTime("00:00") },
      ],
    };
  }

  const entries: IDayTimeMap = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  };

  Object.keys(currentSchedule.schedule).forEach((day) => {
    const daySchedule = currentSchedule.schedule[day];
    if (!daySchedule.length) {
      // Initialize with a default range of "00:00-00:00" if no schedule exists for the day
      entries[day as Day] = [
        { start: getDateForTime("00:00"), end: getDateForTime("00:00") },
      ];
    } else {
      entries[day as Day] = daySchedule.map((entry: string) => {
        const [startTime, endTime] = entry.split("-");
        return {
          start: getDateForTime(startTime),
          end: getDateForTime(endTime),
        };
      });
    }
  });

  return entries;
};

export const formatMenuSchedules = ({
  menuId,
  dayTimeMap,
  selectedDays,
}: {
  menuId: string;
  dayTimeMap: IDayTimeMap;
  selectedDays: Day[];
}): SchedulesByMenuId => {
  const result: SchedulesByMenuId = {
    [menuId]: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
  };

  Object.keys(result[menuId]).forEach((day: Day) => {
    const timeRanges: string[] = [];
    if (dayTimeMap[day] && selectedDays.includes(day)) {
      dayTimeMap[day].forEach(({ end, start }) => {
        const startTime = formatTimeTo24Hour(start); // Format to "HH:mm"
        const endTime = formatTimeTo24Hour(end); // Format to "HH:mm"
        timeRanges.push(`${startTime}-${endTime}`);
      });
      result[menuId][day] = timeRanges;
    }
  });

  return result;
};

export const formatTimeTo24Hour = (date: Date): string => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const checkIfScheduleIsEmpty = (menuSchedule) => {
  for (const day in menuSchedule) {
    const currentDayHours = menuSchedule[day];
    if (currentDayHours.length) {
      return false;
    }
  }
  return true;
};

export const LONG_TO_SHORT_DAYS = {
  monday: "Mon",
  tuesday: "Tue",
  wednesday: "Wed",
  thursday: "Thu",
  friday: "Fri",
  saturday: "Sat",
  sunday: "Sun",
};

export const SHORT_TO_LONG_DAYS = {
  Sun: "Sunday",
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
};

export const defaultSlot = {
  prep_time_minutes: 30,
  start_time: new Date(),
  end_time: new Date(),
  days: {},
};

export const convertPrepTimeRulesToSlots = (
  prepTimeRules?: components["schemas"]["PrepTimeRules"],
) => {
  if (!prepTimeRules) {
    return [];
  }

  const slots: (typeof defaultSlot)[] = [];
  const rules = prepTimeRules.time_based.rules;

  // Map of days to track which rules belong together
  const ruleMap = new Map<string, Set<string>>();

  Object.entries(rules).forEach(([day, dayRules]) => {
    dayRules.forEach((rule) => {
      const [startTime, endTime] = rule.timespan.split("-");
      const ruleKey = `${startTime}-${endTime}-${rule.prep_time_minutes}`;

      // Find or create set for this rule
      let ruleSet = Array.from(ruleMap.values()).find((set) =>
        set.has(ruleKey),
      );

      if (!ruleSet) {
        ruleSet = new Set([ruleKey]);
        ruleMap.set(ruleKey, ruleSet);
      }

      ruleSet.add(day);
    });
  });

  // Convert each unique rule into a slot
  ruleMap.forEach((days, ruleKey) => {
    const [timespan, prepTime] = ruleKey.split("-", 3);
    const [hours, minutes] = timespan.split(":");

    const startTime = new Date();
    startTime.setHours(parseInt(hours), parseInt(minutes), 0);

    const [endHours, endMinutes] = prepTime.split(":");
    const endTime = new Date();
    endTime.setHours(parseInt(endHours), parseInt(endMinutes), 0);

    const daysObject = {};
    days.forEach((day) => {
      daysObject[day.toLowerCase()] = true;
    });

    slots.push({
      prep_time_minutes: parseInt(prepTime),
      start_time: startTime,
      end_time: endTime,
      days: daysObject,
    });
  });

  return slots;
};

export const compareSchedules = ({ pos_schedule, schedule }) => {
  if (!pos_schedule) {
    return {
      isDifferent: false,
      difference: {},
    };
  }
  let isDifferent = false;
  let difference = {};

  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  daysOfWeek.forEach((day) => {
    const posDaySchedule = pos_schedule[day] || [];
    const daySchedule = schedule[day] || [];

    if (JSON.stringify(posDaySchedule) !== JSON.stringify(daySchedule)) {
      difference[day] = { pos_schedule: posDaySchedule, schedule: daySchedule };
      isDifferent = true;
    }
  });

  return {
    isDifferent,
    difference,
  };
};

export const determineNextOpen = (
  schedule: components["schemas"]["MenuLocationSchedule"]["schedule"],
) => {
  const date = new Date();
  const currentDay = date.getDay(); // 0 = Sunday, 1 = Monday, etc.
  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  // Find the next day with opening hours
  let nextOpenDay = -1;
  let daysToAdd = 0;

  for (let i = 1; i <= 7; i++) {
    const checkDay = (currentDay + i) % 7;
    const dayName = daysOfWeek[checkDay];

    if (schedule && schedule[dayName] && schedule[dayName].length > 0) {
      nextOpenDay = checkDay;
      daysToAdd = i;
      break;
    }
  }

  if (nextOpenDay !== -1 && schedule) {
    // Add days to get to the next open day
    date.setDate(date.getDate() + daysToAdd);

    // Get the opening hours from the schedule
    const openingHours = schedule[daysOfWeek[nextOpenDay]][0];
    if (openingHours) {
      const [openTime] = openingHours.split("-");
      const [hours, minutes] = openTime.split(":").map(Number);

      // Set the time to the opening time
      date.setHours(hours, minutes, 0, 0);
    }
  } else {
    // Fallback if no schedule found
    date.setHours(date.getHours() + 1, date.getMinutes(), 0, 0);
  }

  return date;
};
