"use client";

import * as PopoverPrimitive from "@radix-ui/react-popover";
import * as React from "react";

import { cn } from "#/lib/utils";
import { Button } from "#/ui/button";
import { Check } from "lucide-react";

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        "z-50 w-72 rounded-xl border bg-card p-4  text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        className,
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
const ViewMorePopover = ({
  relationships,
  renderItem,
  max = 1,
}: {
  max?: number;
  renderItem?: (item: {
    _id: string;
    name?: string;
    image?: string;
  }) => React.ReactNode;
  relationships: { _id: string; name?: string; image?: string }[];
}) => {
  if (relationships?.length < max + 1) return <></>;
  return (
    <Popover modal>
      <PopoverTrigger asChild onClick={(e) => e.stopPropagation()}>
        <Button variant="outline" size="sm">{`+ ${
          relationships?.length - max
        }`}</Button>
      </PopoverTrigger>

      <PopoverContent className="flex flex-wrap overflow-hidden">
        {[...relationships]?.map((item) => (
          <React.Fragment key={item?._id}>
            {renderItem ? (
              renderItem(item)
            ) : (
              <>
                {item?.image ? (
                  <div className="border-[1px] mx-1 h-8 w-8 flex relative items-center justify-center border-border rounded-xl">
                    <div className="absolute rounded-full overflow-hidden bg-green-400 right-[-3px] top-[-3px]">
                      <Check className="h-3 w-3 text-white" />
                    </div>
                    <img className="h-4 w-4" src={item?.image} />
                  </div>
                ) : (
                  <div
                    className="relative h-[25px] m-1 items-center justify-center flex flex-col px-5"
                    key={`${item._id}`}
                  >
                    <div className="absolute rounded-[5px] h-full w-full bg-primary opacity-[15%]" />
                    <div className="text-primary truncate font-circular-std font-500 text-sm whitespace-nowrap overflow-ellipsis">
                      {item.name}
                    </div>
                  </div>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export { Popover, PopoverContent, PopoverTrigger, ViewMorePopover };
