"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import { Check, ChevronDown, ChevronUp } from "lucide-react";
import * as React from "react";

import useLocations from "#/hooks/data/pos/use-locations";
import { cn } from "#/lib/utils";
import MultiSelect from "#/ui/multi-select";
import { Popover, PopoverTrigger } from "#/ui/popover";
import { BarsArrowDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";

import { CheckIcon, ChevronDownIcon } from "lucide-react";

import { useCurrentLocation } from "#/hooks/use-current-location";
import { queries } from "#/lib/atoms/queries";
import { Button } from "#/ui/button";
import { PopoverContent } from "#/ui/popover";
import clsx from "clsx";
import { useAtom } from "jotai";

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;
const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    titleClassName?: string;
    header?: React.ReactElement | string;
    rightView?: React.ReactElement;
    hideIcon?: boolean;
    title?: string;
    chevronClassName?: string;
  }
>(
  (
    {
      className,
      chevronClassName,
      header,
      title,
      rightView,
      children,
      titleClassName,
      ...props
    },
    ref,
  ) => (
    <div className="relative">
      {rightView && (
        <div
          className={twMerge(
            "text-400 truncate z-50 text-muted text-sm absolute font-circular-std font-500 right-[1.5rem] top-[-0.75rem] bg-background z-10",
            titleClassName,
          )}
        >
          {rightView}
        </div>
      )}
      <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
          "flex w-full relative items-center text-base justify-between rounded-xl text-foreground h-12 pl-2 font-400 border-[1px] px-3 py-2 ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-background [&>span]:line-clamp-1",
          className,
        )}
        {...props}
      >
        {(title || header) && (
          <p
            className={twMerge(
              "text-400 truncate text-muted text-sm absolute font-circular-std font-500 left-[1rem] top-[-0.75rem] bg-background z-10",
              titleClassName,
            )}
          >
            {title || header}
          </p>
        )}
        {children}
        {!props.hideIcon && (
          <SelectPrimitive.Icon asChild>
            <ChevronDown
              className={cn("h-4 w-4 opacity-50", chevronClassName)}
            />
          </SelectPrimitive.Icon>
        )}
      </SelectPrimitive.Trigger>
    </div>
  ),
);

SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={cn(
      "flex cursor-default items-center justify-center py-1",
      className,
    )}
    {...props}
  >
    <ChevronUp className="h-4 w-4" />
  </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={cn(
      "flex cursor-default items-center justify-center py-1",
      className,
    )}
    {...props}
  >
    <ChevronDown className="h-4 w-4" />
  </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName =
  SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        "relative rounded-xl z-50 max-h-96 min-w-[8rem] overflow-hidden border bg-card text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",

        position === "popper" &&
          "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
        className,
      )}
      position={position}
      {...props}
    >
      <SelectScrollUpButton />
      <SelectPrimitive.Viewport
        className={cn(
          "p-1",
          position === "popper" &&
            "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]",
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
      <SelectScrollDownButton />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn("py-1.5 pl-8 pr-2 text-sm font-400", className)}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      "relative flex w-full cursor-pointer select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-primary focus:text-primary-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className,
    )}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <Check className="h-4 w-4" />
      </SelectPrimitive.ItemIndicator>
    </span>

    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn("-mx-1 my-1 h-px bg-muted", className)}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

const ComposedSelect = ({
  title,
  className,
  placeholder,
  data,
  disabled,
  onChange,
  titleClassName,
  trigger,
  value,
  rightView,
  hideIcon,
}: {
  title?: string | React.ReactElement;
  placeholder?: string;
  titleClassName?: string;
  disabled?: boolean;
  trigger?: React.ReactElement;
  rightView?: React.ReactElement;
  className?: string;
  data: { value: string; label: string }[];
  onChange(value: string): void;
  value: string;
  hideIcon?: boolean;
}) => {
  return (
    <Select onValueChange={onChange} value={value}>
      {trigger || (
        <SelectTrigger
          header={title}
          disabled={disabled}
          titleClassName={titleClassName}
          rightView={rightView}
          hideIcon={hideIcon}
          className={twMerge(
            "items-center justify-between text-center",
            className,
          )}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
      )}
      <SelectContent className="max-h-[20rem]">
        <SelectGroup>
          {data.map((item) => (
            <SelectItem
              onClick={(e) => e?.stopPropagation()}
              key={item.value}
              data-testid={`select-item-${item.value}`}
              value={item.value}
            >
              {item.label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

const MultiLocationsSelect = ({
  selectedLocationIds,
  setSelectedLocationIds,
  enableSelectAll = false,
  title = "",
  className = "",
  onCloseAutoFocus = () => {},
  multiSelect = true,
  disabled = false,
  children,
  placeholder = "Select locations",
}: {
  selectedLocationIds: string[];
  setSelectedLocationIds: (ids: string[]) => void;
  enableSelectAll?: boolean;
  title?: string;
  className?: string;
  onCloseAutoFocus?: () => void;
  multiSelect?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  placeholder?: string;
}) => {
  const { data: locations } = useLocations();
  const currentLocation = useCurrentLocation();
  const [showDSPOnly, setShowDSPOnly] = React.useState(false);
  const [{ data: dspLocations }] = useAtom(queries.dspLocations);

  const selectedLocations = React.useMemo(
    () => locations.filter((l) => selectedLocationIds.includes(l?._id)),
    [locations, selectedLocationIds],
  );

  const buttonText = React.useMemo(() => {
    if (selectedLocations.length === 0) {
      return placeholder;
    } else if (selectedLocations.length > 1) {
      return `${selectedLocations.length} locations selected`;
    } else {
      return selectedLocations[0]?.name || currentLocation?.name;
    }
  }, [selectedLocations, currentLocation, placeholder]);

  const onFilter = React.useCallback(
    (values) => {
      const ids = values?.map((i) => i?.value);
      if (multiSelect) {
        setSelectedLocationIds(ids);
      } else {
        setSelectedLocationIds([ids?.[0]]);
      }
    },
    [setSelectedLocationIds, multiSelect],
  );

  const options = React.useMemo(() => {
    const dspLocationIds = dspLocations?.locations?.map((l) => l?.location_id);
    if (showDSPOnly) {
      return locations
        .filter((l) => dspLocationIds?.includes(l?._id))
        .map((l) => ({
          value: l?._id,
          label: l?.name,
        }));
    }
    return locations.map((l) => ({
      value: l?._id,
      label: l?.name,
    }));
  }, [locations, dspLocations, showDSPOnly]);

  return (
    <MultiSelect
      defaultValues={selectedLocationIds}
      onChange={onFilter}
      title={title || buttonText}
      disableSelect={disabled}
      disabled={disabled}
      classNameTitle="bg-background"
      className={className}
      enableSelectAll={enableSelectAll}
      options={options}
      onCloseAutoFocus={onCloseAutoFocus}
    >
      <div className="inline-flex mb-2 w-full bg-background text-muted-foreground border-b">
        <button
          onClick={() => setShowDSPOnly(false)}
          className={cn(
            "flex-1 inline-flex items-center justify-center px-3 py-1.5 text-xs font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
            !showDSPOnly
              ? "bg-primary text-primary-foreground shadow-sm"
              : "hover:bg-muted-foreground/10",
          )}
        >
          All Locations
        </button>
        <button
          onClick={() => setShowDSPOnly(true)}
          className={cn(
            "flex-1 inline-flex items-center justify-center px-3 py-1.5 text-xs font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
            showDSPOnly
              ? "bg-primary text-primary-foreground shadow-sm"
              : "hover:bg-muted-foreground/10",
          )}
        >
          DSP Connected Only
        </button>
      </div>
    </MultiSelect>
  );
};

const LocationsSelect = ({
  selectedLocationIds,
  setSelectedLocationIds,
  multiSelect = true,
  titleClassName = "",
  title = "",
  placeholder = "Select locations",
}) => {
  const [open, setOpen] = React.useState(false);
  const { data: locations } = useLocations();
  const currentLocation = useCurrentLocation();
  const onFilter = React.useCallback(
    (value) => {
      return () => {
        if (multiSelect) {
          setSelectedLocationIds((prev) => {
            if (prev.includes(value)) {
              return prev.filter((id) => id !== value);
            } else {
              return [...prev, value];
            }
          });
        } else {
          setSelectedLocationIds([value]);
        }
      };
    },
    [setSelectedLocationIds, multiSelect],
  );
  const selectedLocations = React.useMemo(
    () => locations.filter((l) => selectedLocationIds.includes(l?._id)),
    [locations, selectedLocationIds],
  );

  const buttonText = React.useMemo(() => {
    if (selectedLocations.length === 0) {
      return placeholder;
    } else if (selectedLocations.length > 1) {
      return `${selectedLocations.length} locations selected`;
    } else {
      return selectedLocations[0]?.name || currentLocation?.name;
    }
  }, [selectedLocations, currentLocation, placeholder]);

  return (
    <div>
      <Popover onOpenChange={setOpen}>
        <PopoverTrigger asChild className="relative">
          <div>
            {title && (
              <p
                className={twMerge(
                  "text-400 text-muted text-sm absolute font-circular-std bg-background font-500 left-[1rem] top-[-0.75rem] z-10",
                  titleClassName,
                )}
              >
                {title}
              </p>
            )}
            <Button className="px-2" size="md" variant="outline-muted">
              <BarsArrowDownIcon className="h-4 w-4 text-muted" />
              <p className="px-2">{buttonText}</p>
              {open ? (
                <ChevronUpIcon className="h-4 w-4 text-muted" />
              ) : (
                <ChevronDownIcon className="h-4 w-4 text-muted" />
              )}
            </Button>
          </div>
        </PopoverTrigger>
        <PopoverContent className="py-2">
          {locations?.map((f, index) => (
            <div
              className={clsx("px-3 cursor-pointer border-border", {
                "border-b-[1px]": index !== locations?.length - 1,
              })}
              key={f?._id}
              onClick={onFilter(f?._id)}
            >
              <div className="text-sm flex h-10 items-center justify-between text-foreground font-circular-regular">
                <p className="truncate">{f?.name}</p>
                {selectedLocationIds.includes(f?._id) ? (
                  <CheckIcon className="h-4 w-4 ml-2 text-green-500" />
                ) : (
                  <div className="w-4 ml-2" />
                )}
              </div>
            </div>
          ))}
        </PopoverContent>
      </Popover>
    </div>
  );
};

const MerchantSelect = ({
  className,
  placeholder,
  data,
  disabled,
  onChange,
  titleClassName,
  value,
}: {
  placeholder?: string;
  titleClassName?: string;
  disabled?: boolean;
  className?: string;
  data: { value: string; label: string; image: string; org?: string }[];
  onChange(value: string): void;
  value: string;
}) => {
  const merchant = data?.find((m) => m.value === value);

  return (
    <Select onValueChange={onChange} value={value}>
      <SelectTrigger
        disabled={disabled}
        titleClassName={titleClassName}
        className={twMerge(
          "items-center justify-center h-10 space-x-3 text-sm text-center",
          className,
        )}
      >
        <div className="flex border-[1px] border-border rounded-full overflow-hidden items-center">
          {merchant?.image ? (
            <img
              src={merchant?.image}
              alt={merchant?.label}
              className="h-6 w-6"
            />
          ) : (
            <div className="h-6 w-6 bg-border" />
          )}
        </div>
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent className="max-h-[20rem]">
        <SelectGroup>
          {data.map((item) => {
            return (
              <SelectItem
                onClick={(e) => e?.stopPropagation()}
                key={item.value}
                value={item.value}
              >
                <p> {item.label}</p>
                {item?.value !== value && (
                  <p className="text-muted text-xs">{item.org}</p>
                )}
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export {
  ComposedSelect,
  LocationsSelect,
  MerchantSelect,
  MultiLocationsSelect,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
};
