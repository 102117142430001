export type IFixMe = any;

export type Sources =
  | "chownow"
  | "flipdish"
  | "grubhub"
  | "google"
  | "online_ordering"
  | "uber"
  | "doordash";

export interface Theme {
  borderColor: string;
  primaryColor: string;
  accentColor: string;
  primaryForegroundColor: string;
  primaryMutedColor: string;
  secondaryColor: string;
  secondaryForegroundColor: string;
  secondaryMutedColor: string;
  portalLink?: string;
  secondaryBorderColor: string;
  mutedColor: string;
  bgColor: string;
  foregroundColor: string;
  cardColor: string;
  cardForegroundColor: string;
  destructiveColor: string;
  destructiveForegroundColor: string;
  imageUrl: string;
  iconUrl: string;
  secondaryAccentColor: string;
  name: string /* brand name */;
  /** Feature flags for white label */
  flags: {
    aiEnabled?: boolean;
    intercomEnabled?: boolean;
    customRolesEnabled?: boolean;
    snoozeEnabled?: boolean;
    throttlingEnabled?: boolean;
  };
}

export type ValueOf<T> = T[keyof T];

export enum DatadogActionNames {
  "cancel_subscription" = "cancel-subscription",
  "confirm_cancel" = "confirm-cancel",
  "confirm_cancel_premium" = "confirm-cancel-premium",
  "dismiss_cancel" = "dismiss-cancel",
  "dismiss_upgrade" = "dismiss-upgrade",
  "upgrade_premium" = "upgrade-premium",
  "subscribe_premium" = "subscribe-premium",
  "subscribe_standard" = "subscribe-standard",
  "downgrade" = "downgrade",
}
