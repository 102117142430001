"use client";

import { Card } from "#/ui/card";
import { Separator } from "#/ui/separator";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface KitchenCapacityChartProps {
  kitchen_capacity?: number;
  default_prep_time?: number;
}

export function KitchenCapacityChart({
  kitchen_capacity = 0,
  default_prep_time = 0,
}: KitchenCapacityChartProps) {
  // Calculate intervals based on default_prep_time
  const interval = default_prep_time || 15;
  // If interval is <= 20, use 0-5-10-15-20 pattern, else use 0-30-60-90-120 pattern
  const useShortIntervals = interval <= 20;

  const firstInterval = useShortIntervals ? interval : interval * 2;
  const secondInterval = useShortIntervals ? interval * 2 : interval * 4;
  const thirdInterval = useShortIntervals ? interval * 3 : interval * 6;
  const fourthInterval = useShortIntervals ? interval * 4 : interval * 8;

  // Calculate capacity levels based on kitchen_capacity
  const baseCapacity = kitchen_capacity || 30;
  const greenLevel = baseCapacity;
  const yellowLevel = baseCapacity * 2;
  const orangeLevel = baseCapacity * 3;
  const redLevel = baseCapacity * 4;

  // Generate data points to create L-shaped sections with sharp vertical drops
  const data = [];
  for (let i = 0; i <= fourthInterval; i++) {
    // Add point before drop
    if (
      i === firstInterval ||
      i === secondInterval ||
      i === thirdInterval ||
      i === fourthInterval
    ) {
      data.push({
        time: i,
        green: i <= firstInterval ? greenLevel : 0,
        yellow: i <= secondInterval ? yellowLevel : 0,
        orange: i <= thirdInterval ? orangeLevel : 0,
        red: redLevel,
      });
      // Add point after drop (same x value, different y value)
      data.push({
        time: i,
        green: i < firstInterval ? greenLevel : 0,
        yellow: i < secondInterval ? yellowLevel : 0,
        orange: i < thirdInterval ? orangeLevel : 0,
        red: redLevel,
      });
    } else {
      data.push({
        time: i,
        green: i < firstInterval ? greenLevel : 0,
        yellow: i < secondInterval ? yellowLevel : 0,
        orange: i < thirdInterval ? orangeLevel : 0,
        red: redLevel,
      });
    }
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const time = label;
      let defaultQueue = "";
      let throttledQueue = "";
      let totalPrepTime = "";

      if (time <= firstInterval) {
        defaultQueue = greenLevel.toString();
        throttledQueue = "0";
        totalPrepTime = firstInterval.toString();
      } else if (time <= secondInterval) {
        defaultQueue = greenLevel.toString();
        throttledQueue = yellowLevel.toString();
        totalPrepTime = secondInterval.toString();
      } else if (time <= thirdInterval) {
        defaultQueue = greenLevel.toString();
        throttledQueue = orangeLevel.toString();
        totalPrepTime = thirdInterval.toString();
      } else {
        defaultQueue = greenLevel.toString();
        throttledQueue = redLevel.toString();
        totalPrepTime = fourthInterval.toString();
      }

      return (
        <Card className="p-3 bg-secondary rounded-xl text-white">
          <div className="space-y-3">
            <div className="flex items-center gap-2 justify-between">
              <div>
                <p className="text-sm text-secondary-muted">Number of items</p>
                <p className="text-sm font-medium">{`${defaultQueue}-${throttledQueue}`}</p>
              </div>
              <div>
                <p className="text-sm text-secondary-muted">Prep time</p>
                <p className="text-sm font-medium">{`${totalPrepTime} minutes`}</p>
              </div>
            </div>
            <Separator className="bg-secondary-muted opacity-50" />
            <div className="mt-2 flex items-center gap-2">
              <div className="flex items-center gap-1">
                <p className="text-xs text-muted-foreground">Default Queue</p>
                <p className="text-xs font-medium">{defaultQueue}</p>
              </div>
              <div className="flex items-center gap-1">
                <p className="text-xs text-muted-foreground">Throttled Queue</p>
                <p className="text-xs font-medium">{throttledQueue}</p>
              </div>
            </div>
          </div>
        </Card>
      );
    }
    return null;
  };

  return (
    <div className="h-[400px] w-full rounded-lg bg-white p-4">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 10,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" className="opacity-50" />
          <XAxis
            dataKey="time"
            type="number"
            domain={[0, fourthInterval]}
            ticks={[
              0,
              firstInterval,
              secondInterval,
              thirdInterval,
              fourthInterval,
            ]}
            tickFormatter={(value) => `${value}m`}
            label={{
              value: "Prep time (minutes)",
              position: "insideBottom",
              offset: -20,
            }}
          />
          <YAxis
            domain={[0, redLevel]}
            ticks={[0, greenLevel, yellowLevel, orangeLevel, redLevel]}
            label={{
              value: "Kitchen Capacity",
              angle: -90,
              position: "insideLeft",
              offset: 10,
            }}
          />
          <Tooltip
            content={(props: { active: any; payload: any; label: any }) => (
              <CustomTooltip {...props} />
            )}
          />
          <Area
            dataKey="red"
            stroke="#fecdd3"
            fill="#fecdd3"
            fillOpacity={0.4}
            strokeWidth={0}
            isAnimationActive={false}
          />
          <Area
            dataKey="orange"
            stroke="#fed7aa"
            fill="#fed7aa"
            fillOpacity={0.4}
            strokeWidth={0}
            isAnimationActive={false}
          />
          <Area
            dataKey="yellow"
            stroke="#fef08a"
            fill="#FCF0D4"
            fillOpacity={1}
            strokeWidth={0}
            isAnimationActive={false}
          />
          <Area
            dataKey="green"
            stroke="#bbf7d0"
            fill="#D8F2EA"
            fillOpacity={1}
            strokeWidth={0}
            isAnimationActive={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export function KitchenCapacityChartMini({
  kitchen_capacity = 0,
  default_prep_time = 0,
}: KitchenCapacityChartProps) {
  // Calculate intervals based on default_prep_time
  const interval = default_prep_time || 15;
  const useShortIntervals = interval <= 20;

  const firstInterval = useShortIntervals ? interval : interval * 2;
  const secondInterval = useShortIntervals ? interval * 2 : interval * 4;
  const thirdInterval = useShortIntervals ? interval * 3 : interval * 6;
  const fourthInterval = useShortIntervals ? interval * 4 : interval * 8;

  // Calculate capacity levels based on kitchen_capacity
  const baseCapacity = kitchen_capacity || 30;
  const greenLevel = baseCapacity;
  const yellowLevel = baseCapacity * 2;
  const orangeLevel = baseCapacity * 3;
  const redLevel = baseCapacity * 4;

  // Generate data points to create L-shaped sections with sharp vertical drops
  const data = [];
  for (let i = 0; i <= fourthInterval; i++) {
    if (
      i === firstInterval ||
      i === secondInterval ||
      i === thirdInterval ||
      i === fourthInterval
    ) {
      data.push({
        time: i,
        green: i <= firstInterval ? greenLevel : 0,
        yellow: i <= secondInterval ? yellowLevel : 0,
        orange: i <= thirdInterval ? orangeLevel : 0,
        red: redLevel,
      });
      data.push({
        time: i,
        green: i < firstInterval ? greenLevel : 0,
        yellow: i < secondInterval ? yellowLevel : 0,
        orange: i < thirdInterval ? orangeLevel : 0,
        red: redLevel,
      });
    } else {
      data.push({
        time: i,
        green: i < firstInterval ? greenLevel : 0,
        yellow: i < secondInterval ? yellowLevel : 0,
        orange: i < thirdInterval ? orangeLevel : 0,
        red: redLevel,
      });
    }
  }

  return (
    <div className="h-[200px] w-full rounded-lg bg-white">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={data}
          margin={{
            top: 5,
            right: 10,
            left: 0,
            bottom: 15,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" className="opacity-30" />
          <XAxis
            dataKey="time"
            type="number"
            domain={[0, fourthInterval]}
            ticks={[
              0,
              firstInterval,
              secondInterval,
              thirdInterval,
              fourthInterval,
            ]}
            tickFormatter={(value) => `${value}m`}
            tick={{ fontSize: 10 }}
          />
          <YAxis
            domain={[0, redLevel]}
            ticks={[0, greenLevel, yellowLevel, orangeLevel, redLevel]}
            tick={{ fontSize: 10 }}
          />
          <Area
            dataKey="red"
            stroke="#fecdd3"
            fill="#fecdd3"
            fillOpacity={0.4}
            strokeWidth={0}
            isAnimationActive={false}
          />
          <Area
            dataKey="orange"
            stroke="#fed7aa"
            fill="#fed7aa"
            fillOpacity={0.4}
            strokeWidth={0}
            isAnimationActive={false}
          />
          <Area
            dataKey="yellow"
            stroke="#fef08a"
            fill="#FCF0D4"
            fillOpacity={1}
            strokeWidth={0}
            isAnimationActive={false}
          />
          <Area
            dataKey="green"
            stroke="#bbf7d0"
            fill="#D8F2EA"
            fillOpacity={1}
            strokeWidth={0}
            isAnimationActive={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
