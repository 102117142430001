"use client";

import { KitchenCapacityChartMini } from "#/components/charts/kitchen-capacity-chart";
import useSubscription from "#/hooks/data/subscription/use-subscription";
import { useTStream } from "#/hooks/use-t-stream";
import { Button } from "#/ui/button";
import { Separator } from "#/ui/separator";
import { FormatCents } from "#/utils/currency.utils";
import { SUBSCRIPTION_TIERS } from "#/utils/subscription.utils";
import { StarIcon } from "@heroicons/react/24/solid";
import { Check } from "lucide-react";
import { useCallback } from "react";

export const PrepTimeKitchenSubscriptionView = ({
  setShowSuccess,
  onOpenChange,
}: {
  setShowSuccess: (show: boolean) => void;
  onOpenChange: (open: boolean) => void;
}) => {
  const { t } = useTStream("common");
  const { create, isUpdating, priceInfo, allFeatures } = useSubscription();

  const features = allFeatures.filter((feature) =>
    feature.includedIn.includes("premium"),
  );

  const onUpgrade = useCallback(async () => {
    await create(SUBSCRIPTION_TIERS.per_month_location_premium);
    setShowSuccess(true);
  }, [create, setShowSuccess]);

  return (
    <>
      <div className="w-full flex items-center justify-center h-[310px] bg-background">
        <div className="relative">
          <div className="w-[330px] relative overflow-hidden p-4 space-y-2 shadow-xl h-[230px] bg-card rounded-xl">
            <div className="gap-2">
              <p className="text-[12px]">Kitchen Capacity (orders)</p>
              <p className="text-[10px] text-muted">
                How many items can your kitchen handle at once?
              </p>
            </div>

            <div className="left-[-10px] w-[300px] absolute bottom-[-20px]">
              <KitchenCapacityChartMini />
            </div>
          </div>
          <div className="w-[180px] p-4 space-y-2 items-center justify-center flex flex-col absolute right-[-50px] bottom-[-25px] z-10 h-[120px] bg-card shadow-xl rounded-xl">
            {/* STAR */}
            <div className="relative w-full">
              <div className="rounded-full bg-purple-500 p-2 absolute right-[-30px] top-[-30px]">
                <div className="absolute inset-0 rounded-full bg-purple-500 animate-ping opacity-30"></div>
                <StarIcon className="h-5 w-5 text-white relative z-10" />
              </div>
            </div>

            <div className="gap-2">
              <p className="text-[12px]">Kitchen Capacity (orders)</p>
              <p className="text-[10px] text-muted">
                How many items can your kitchen handle at once?
              </p>
            </div>
            <input
              type="number"
              min="1"
              disabled
              placeholder="Enter capacity..."
              className="w-full border rounded-xl text-xs bg-background px-3 py-1 border-border text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
            />
          </div>
        </div>
      </div>
      <div className="px-10 space-y-4 pb-4">
        <div className="flex flex-col items-center">
          <div className="space-y-1 text-start">
            <h2 className="text-xl mt-3 font-500 tracking-tight">
              Maximize Efficiency with Kitchen Capacity Management
            </h2>
            <p className="text-muted mt-4 font-400 text-sm">
              Set a capacity limit to automatically manage the number of active
              orders your kitchen can handle at once. When the limit is reached,
              new orders are queued until space frees up, ensuring a consistent
              pace without overwhelming your team.
            </p>
          </div>
        </div>

        <div>
          <div className="space-y-4">
            <h3 className="text-sm font-500">
              {t("Everything in Standard, plus:")}
            </h3>
            <ul className="grid grid-cols-2 gap-3">
              {features
                .filter((feature) => !feature.includedIn.includes("standard"))
                .map((feature) => (
                  <li key={feature.name} className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-emerald-500" />
                    <span className="text-muted text-xs">{feature.name}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <Separator />

        <div className="space-y-1">
          <div className="text-2xl font-500">
            {FormatCents(
              priceInfo?.premium?.unit_amount,
              priceInfo?.premium?.currency,
            )}
          </div>
          <p className="text-sm text-muted">{t("Per location / month")}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <Button
            onClick={() => onOpenChange(false)}
            variant="outline"
            className="w-full"
          >
            {t("Maybe later")}
          </Button>
          <Button className="w-full" onClick={onUpgrade} loading={isUpdating}>
            {t("Upgrade now")}
          </Button>
        </div>
      </div>
    </>
  );
};
