"use client";

import useCancelSubscription from "#/hooks/data/subscription/use-cancel-subscription";
import useSubscription from "#/hooks/data/subscription/use-subscription";
import { useTStream } from "#/hooks/use-t-stream";
import { DatadogActionNames } from "#/types.ts/other";
import { Button } from "#/ui/button";
import { DrawerDialogStacked } from "#/ui/drawer-dialog";
import { Textarea } from "#/ui/textinput";
import { SUBSCRIPTION_TIERS } from "#/utils/subscription.utils";
import { ReactElement, useCallback, useState } from "react";

export const ConfirmSubscriptionCancelDrawerDialog = ({
  trigger,
  downgrade,
  onSuccess,
}: {
  trigger: ReactElement;
  downgrade: boolean;
  onSuccess: () => void;
}) => {
  const { t } = useTStream("common");
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const { cancel, isCancelLoading, subscription } = useCancelSubscription();
  const { create, loading } = useSubscription();

  const onCancel = useCallback(async () => {
    const data = await cancel({
      return_url: `${window.location.protocol}//${window.location.host}/settings/company`,
      subscription: subscription,
      reason,
    });
    if (data?.url) {
      window.location.assign(data.url);
    }
  }, [cancel, reason, subscription]);

  const onDowngrade = useCallback(async () => {
    await create(SUBSCRIPTION_TIERS.per_month_location);
    onSuccess();
    setOpen(false);
  }, [create, setOpen]);

  return (
    <DrawerDialogStacked
      open={open}
      onOpenChange={setOpen}
      trigger={trigger}
      title={t("Why are you unsubscribing?")}
      description={t(
        "Could you share why you decided to unsubscribe? Your feedback is invaluable and helps us improve.",
      )}
    >
      <div className="flex flex-col gap-4 w-full">
        <Textarea
          name="reason"
          className="border-accent"
          placeholder={t("Reason")}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />

        <div className="flex items-center gap-2 w-full">
          <Button
            className="w-full"
            variant="outline-muted"
            onClick={() => setOpen(false)}
          >
            {t("Go back")}
          </Button>
          {downgrade ? (
            <Button
              className="w-full"
              onClick={onDowngrade}
              loading={loading}
              data-dd-action-name={DatadogActionNames.confirm_cancel}
            >
              {t("Downgrade to Standard plan")}
            </Button>
          ) : (
            <Button
              variant="destructive"
              className="w-full"
              onClick={onCancel}
              loading={isCancelLoading}
              data-dd-action-name={
                subscription?.subscription?.type_hr ===
                SUBSCRIPTION_TIERS.per_month_location_premium
                  ? DatadogActionNames.confirm_cancel_premium
                  : DatadogActionNames.confirm_cancel
              }
            >
              {t("Cancel subscription")}
            </Button>
          )}
        </div>
      </div>
    </DrawerDialogStacked>
  );
};
