import { ChangeEventHandler, useCallback } from "react";
import { twMerge } from "tailwind-merge";

type CheckboxProps = {
  className?: string;
  id?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const Checkbox = ({
  className,
  id,
  checked,
  onChange,
}: CheckboxProps) => {
  const onClick = useCallback((e) => e.stopPropagation(), []);
  return (
    <input
      id={id}
      onClick={onClick}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      className={twMerge(
        "h-5 rounded-[5px] focus:ring-0 focus:border-primary border-muted cursor-pointer focus:ring-primary w-5 text-primary",
        className,
      )}
    />
  );
};
