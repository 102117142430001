"use client";

import { ConfirmSubscriptionCancelDrawerDialog } from "#/components/modals/subscription/confirm-subscription-cancel-drawer-dialog";
import useSubscription from "#/hooks/data/subscription/use-subscription";
import { useTStream } from "#/hooks/use-t-stream";
import { cn } from "#/lib/utils";
import { DatadogActionNames } from "#/types.ts/other";
import { Badge } from "#/ui/badge";
import { Button } from "#/ui/button";
import { Card } from "#/ui/card";
import { FormatCents } from "#/utils/currency.utils";
import { SUBSCRIPTION_TIERS } from "#/utils/subscription.utils";
import { StarIcon } from "@heroicons/react/24/solid";
import { Check, StoreIcon, XIcon } from "lucide-react";
import { useCallback } from "react";

export const SubscriptionTierView = ({
  tier,
  setOpen,
  setShowSuccess,
  className,
}: {
  tier: SUBSCRIPTION_TIERS;
  setOpen?: (open: boolean) => void;
  setShowSuccess: (show: boolean) => void;
  className?: string;
}) => {
  const { subscription, allFeatures, isActive } = useSubscription();

  const isPremium = tier === SUBSCRIPTION_TIERS.per_month_location_premium;
  const { create, loading, priceInfo } = useSubscription();
  const { t } = useTStream("common");

  const onSelect = useCallback(async () => {
    await create(tier);
    setShowSuccess(true);
    if (setOpen) setOpen(false);
  }, [create, setOpen, tier, setShowSuccess]);

  return (
    <Card
      className={cn(
        {
          "p-5 rounded-xl": true,
          "bg-secondary text-secondary-foreground": isPremium,
          "bg-background text-secondary-foreground": !isPremium,
        },
        className,
      )}
    >
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-center gap-4">
          <div
            className={`p-3 rounded-full flex relative overflow-hidden items-center justify-center ${
              isPremium ? "bg-[#8257e6]" : ""
            }`}
          >
            {isPremium ? (
              <StarIcon className="w-4 h-4 text-white" />
            ) : (
              <>
                <div className="absolute w-full h-full bg-primary opacity-[15%]" />
                <StoreIcon className="w-4 h-4 text-primary" />
              </>
            )}
          </div>
          <div>
            <div className="flex items-center space-x-3">
              <h2
                className={`text-lg font-500 mb-1 ${
                  isPremium ? "text-secondary-foreground" : "text-foreground"
                }`}
              >
                {isPremium ? t("Premium") : t("Standard")}
              </h2>
            </div>
            <p
              className={`text-xs ${
                isPremium ? "text-secondary-muted" : "text-foreground"
              }`}
            >
              {isPremium
                ? t("Unlock full potential of Stream features")
                : t("Get full access to manage your orders")}
            </p>
          </div>
        </div>

        {/* Features Section */}
        <div className="space-y-6">
          <h3
            className={`text-base ${
              isPremium ? "text-secondary-foreground" : "text-foreground"
            }`}
          >
            {t("All items")}
          </h3>
          <ul className="space-y-4">
            {allFeatures.map((feature) => {
              const isIncluded = feature.includedIn.includes(
                isPremium ? "premium" : "standard",
              );
              return (
                <li
                  key={feature.name}
                  className={`flex items-center gap-3 ${
                    isIncluded ? "" : "opacity-50"
                  }`}
                >
                  {isIncluded ? (
                    <Check className="w-5 h-5 text-[#3dd598]" />
                  ) : (
                    <XIcon className="w-5 h-5 text-muted" />
                  )}
                  <span
                    className={`text-xs ${isPremium ? "" : "text-foreground"} ${
                      !isIncluded ? "line-through" : ""
                    }`}
                  >
                    {feature.name}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>

        {/* Pricing and CTA */}
        <div
          className={`flex items-end border-t-[1px] ${
            isPremium ? "border-secondary-accent" : "border-border"
          } justify-between pt-4`}
        >
          <div>
            <span
              className={`text-xl font-600 ${
                isPremium ? "" : "text-foreground"
              }`}
            >
              {isPremium
                ? FormatCents(
                    priceInfo?.premium?.unit_amount,
                    priceInfo?.premium?.currency,
                  )
                : FormatCents(priceInfo?.unit_amount, priceInfo?.currency)}
            </span>
            <p
              className={`text-sm ${
                isPremium ? "text-secondary-muted" : "text-muted"
              }`}
            >
              {t("Per location / month")}
            </p>
          </div>
          {(!subscription?.type_hr || subscription?.type_hr !== tier) && (
            <>
              {isPremium ? (
                <Button
                  variant={isPremium ? "default" : "outline-muted"}
                  onClick={onSelect}
                  loading={loading}
                  size="md"
                  data-dd-action-name={
                    isPremium
                      ? DatadogActionNames.subscribe_premium
                      : isActive
                      ? DatadogActionNames.downgrade
                      : DatadogActionNames.subscribe_standard
                  }
                >
                  {isPremium
                    ? t("Upgrade")
                    : isActive
                    ? t("Downgrade")
                    : "Upgrade"}
                </Button>
              ) : (
                <>
                  {isActive ? (
                    <ConfirmSubscriptionCancelDrawerDialog
                      downgrade={isActive}
                      trigger={
                        <Button
                          variant="outline-muted"
                          size="md"
                          data-dd-action-name={DatadogActionNames.downgrade}
                        >
                          {t("Downgrade")}
                        </Button>
                      }
                      onSuccess={() => setShowSuccess(true)}
                    />
                  ) : (
                    <Button
                      variant="outline-muted"
                      onClick={onSelect}
                      loading={loading}
                      size="md"
                      data-dd-action-name={
                        DatadogActionNames.subscribe_standard
                      }
                    >
                      {"Upgrade"}
                    </Button>
                  )}
                </>
              )}
            </>
          )}
          {subscription?.type_hr === tier && (
            <Badge variant={isPremium ? "default" : "muted"}>
              {t("Your plan")}
            </Badge>
          )}
        </div>
      </div>
    </Card>
  );
};
