import { useTStream } from "#/hooks/use-t-stream";
import { mutations } from "#/lib/atoms/mutations";
import { queries } from "#/lib/atoms/queries";
import { ThemeContext } from "#/providers/theme-provider";
import {
  SUBSCRIPTION_TIERS,
  isActiveSubscription,
} from "#/utils/subscription.utils";
import { useAtom } from "jotai";

import { useCallback, useContext, useMemo, useState } from "react";

export default function useSubscription() {
  const { t } = useTStream("common");
  const [{ data: subscriptionResponse }] = useAtom(queries.subscription);
  const [{ data: invoiceData }] = useAtom(queries.latestInvoice);
  const [{ mutateAsync: update, isPending: isUpdating }] = useAtom(
    mutations.updateSubscription,
  );

  const status = subscriptionResponse?.subscription?.status;
  const lastInvoice = invoiceData?.invoice || null;

  const [{ mutateAsync: createSubscription }] = useAtom(
    mutations.createSubscription,
  );
  const { flags } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const isActive = useMemo(
    () => isActiveSubscription(subscriptionResponse?.subscription),
    [subscriptionResponse],
  );

  // Clover / POS marketplace bypass - they can still upgrade
  const isByPassed = useMemo(
    () => subscriptionResponse?.subscription?._id === "N/A",
    [subscriptionResponse],
  );

  // Partner sub bypass - they can not upgrade or ever see stripe
  const isPartnerByPassed = useMemo(
    () => subscriptionResponse?.subscription?._id === "N/A_PARTNER",
    [subscriptionResponse],
  );

  const create = useCallback(
    async (tier: SUBSCRIPTION_TIERS) => {
      try {
        setLoading(true);
        if (isActive) {
          await update({
            _id: subscriptionResponse?.subscription?._id,
            type: tier,
          });
        } else {
          await createSubscription(tier);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      createSubscription,
      update,
      subscriptionResponse?.subscription?._id,
      isActive,
    ],
  );
  const allFeatures = useMemo(() => {
    const features = [
      { name: t("Automatic POS Sync"), includedIn: ["standard", "premium"] },
      {
        name: t("Channel-wide Price Editing"),
        includedIn: ["standard", "premium"],
      },
      {
        name: t("Unlimited Integrations & Orders"),
        includedIn: ["standard", "premium"],
      },
      { name: t("Snoozing Items"), includedIn: ["premium"] },
      { name: t("Custom Permissions"), includedIn: ["premium"] },

      ...(flags?.throttlingEnabled
        ? [
            {
              name: t("Order Throttling"),
              includedIn: ["premium"],
            },
          ]
        : []),
      { name: t("Reporting (coming soon)"), includedIn: ["premium"] },
    ];

    return features;
  }, [t, flags?.throttlingEnabled]);

  return {
    create,
    allFeatures,
    isActive,
    isByPassed,
    isPartnerByPassed,
    lastInvoice,
    isUpdating,
    status,
    subscription: subscriptionResponse?.subscription,
    priceInfo: subscriptionResponse?.price_info,
    isLoading: subscriptionResponse === null,
    loading,
    isPremium:
      subscriptionResponse?.subscription?.type_hr ===
      SUBSCRIPTION_TIERS.per_month_location_premium,
  };
}
