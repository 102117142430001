import useSubscription from "#/hooks/data/subscription/use-subscription";
import { useTStream } from "#/hooks/use-t-stream";
import { atoms } from "#/lib/atoms/atoms";
import { queryClient } from "#/lib/query";
import { components } from "#/types.ts/swagger";
import { Button } from "#/ui/button";
import { SUBSCRIPTION_TIERS } from "#/utils/subscription.utils";
import { motion } from "framer-motion";
import { useAtom } from "jotai";
import { Check, Loader2 } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import { useDebounceCallback } from "usehooks-ts";

export const SubscriptionCongratsView = (props: { onContinue: () => void }) => {
  const { width, height } = useWindowSize();
  const { t } = useTStream("common");
  const { subscription } = useSubscription();
  const [initialSubscription] = useState(subscription?.type_hr);
  const [sub] = useState(subscription?.type_hr);
  const [accessToken] = useAtom(atoms.accessToken);
  const [orgId] = useAtom(atoms.orgId);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  const isUpgrade = useMemo(() => {
    return sub === SUBSCRIPTION_TIERS.per_month_location;
  }, [sub, initialSubscription]);

  const pollForUpdate = useDebounceCallback(async () => {
    let attempts = 0;
    const maxAttempts = 3;

    while (attempts < maxAttempts) {
      const result: components["schemas"]["GetSubscriptionResponseDto"] =
        await queryClient.fetchQuery({
          queryKey: ["querySubscription", accessToken, orgId],
          staleTime: 0,
        });

      if (result?.subscription?.type_hr !== sub) {
        setIsSuccess(true);
        setIsLoading(false);
        break;
      }

      attempts++;
      if (attempts === maxAttempts) {
        setIsLoading(false);
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }, 1000);

  useEffect(() => {
    pollForUpdate();
  }, [accessToken, orgId]);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] text-center px-6">
        <Loader2 className="w-12 h-12 animate-spin text-primary" />
        <p className="text-gray-600 mt-4">
          {t("Processing your subscription...")}
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-[400px] text-center px-6">
      {isUpgrade && (
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={500}
        />
      )}

      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
        <div className="relative">
          <motion.div
            className="w-24 h-24 bg-emerald-100 rounded-full flex items-center justify-center"
            animate={{
              scale: [1, 1.1, 1],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{
                delay: 0.2,
                type: "spring",
                stiffness: 300,
              }}
            >
              <Check className="w-12 h-12 text-emerald-500" strokeWidth={3} />
            </motion.div>
          </motion.div>
          <motion.div
            className="absolute -top-2 -right-2"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.5 }}
          >
            <span className="text-4xl">✨</span>
          </motion.div>
        </div>
      </motion.div>

      <motion.h2
        className="text-2xl font-bold mt-10"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
      >
        {isUpgrade
          ? t("Congratulations!")
          : t("Premium subscription cancelled")}
      </motion.h2>
      <motion.p
        className="text-gray-600 mt-4 max-w-md mx-auto"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        {isUpgrade
          ? t(
              "Your upgrade was successful. You now have access to all premium features.",
            )
          : t(
              "Your subscription has been downgraded. You will no longer have access to premium features.",
            )}
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7 }}
        className="mt-8"
      >
        <Button onClick={props?.onContinue} className="w-full">
          {t("Continue")}
        </Button>
      </motion.div>
    </div>
  );
};
