"use client";

import { PrepTimeKitchenSubscriptionView } from "#/components/modals/subscription/preptime-kitchen-subscription-view";
import { SnoozeSubscriptionView } from "#/components/modals/subscription/snooze-subscription-view";
import { SubscriptionTierView } from "#/components/modals/subscription/subscription-tier-view";
import { EnterpriseSection } from "#/components/subscription/enterprise-section";
import { SubscriptionCongratsView } from "#/components/subscription/subscription-congrats-view";
import useSubscription from "#/hooks/data/subscription/use-subscription";
import { useTStream } from "#/hooks/use-t-stream";
import { analytics, events } from "#/lib/analytics";
import { cn } from "#/lib/utils";
import { DatadogActionNames } from "#/types.ts/other";
import { Button } from "#/ui/button";
import { DrawerDialogBase } from "#/ui/drawer-dialog";
import { Separator } from "#/ui/separator";
import { FormatCents } from "#/utils/currency.utils";
import { SUBSCRIPTION_TIERS } from "#/utils/subscription.utils";
import { StarIcon } from "@heroicons/react/24/solid";
import { Check } from "lucide-react";
import {
  cloneElement,
  ReactElement,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDebouncedCallback } from "use-debounce";

type SubscriptionModalProps = {
  tierPermissions?: SUBSCRIPTION_TIERS[];
  /** Feature entry point, if no component is provided we will override onClick */
  children?: ReactElement;
  /** Paywall specific component to show when not subscribed */
  component?: ReactElement;
  manage?: boolean;
  paywall?: "snooze" | "kitchen_capacity";
};

export const SubscriptionModal = (props: SubscriptionModalProps) => (
  <Suspense fallback={<></>}>
    <SubscriptionComponent {...props} />
  </Suspense>
);

const SubscriptionComponent = ({
  children,
  tierPermissions,
  component,
  paywall,
  manage = false,
}: SubscriptionModalProps) => {
  const [isOpen, setOpen] = useState(false);
  const { subscription, priceInfo, isPartnerByPassed } = useSubscription();
  const { t } = useTStream("common");
  const [showSuccess, setShowSuccess] = useState(false);

  const onView = useDebouncedCallback(() => {
    analytics.track(events.SUBSCRIPTION_STARTED, {
      unit_amount: priceInfo?.unit_amount,
      currency: priceInfo?.currency,
    });
  }, 1000);

  useEffect(() => {
    if (isOpen) {
      setShowSuccess(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !manage) onView();
  }, [isOpen, manage]);

  const hasRequiredTier = useMemo(() => {
    if (manage) return true;
    if (isPartnerByPassed) return true;
    if (!subscription) return false;
    if (!tierPermissions?.length) return true;

    const currentTier = subscription.type_hr as SUBSCRIPTION_TIERS;
    return tierPermissions.includes(currentTier);
  }, [subscription, tierPermissions, manage]);

  const showUpgrade = useMemo(() => {
    if (manage) return false;
    if (!subscription?.type_hr || !tierPermissions?.length) return false;

    const currentTierIndex = Object.values(SUBSCRIPTION_TIERS).indexOf(
      subscription.type_hr as SUBSCRIPTION_TIERS,
    );
    const minRequiredTierIndex = Math.min(
      ...tierPermissions.map((tier) =>
        Object.values(SUBSCRIPTION_TIERS).indexOf(tier),
      ),
    );

    return currentTierIndex < minRequiredTierIndex;
  }, [subscription, tierPermissions, manage]);

  if (!hasRequiredTier || manage) {
    return (
      <>
        {component &&
          cloneElement(component, {
            ...component.props,
            onClick: (e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(true);
            },
            onPointerDown: (e: React.PointerEvent) => {
              e.preventDefault();
              e.stopPropagation();
            },
          })}

        {/* If you render children here, we will leak access to the feature */}
        {children &&
          !component &&
          cloneElement(children, {
            ...children?.props,
            onClick: (e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(true);
            },
            onPointerDown: (e: React.PointerEvent) => {
              e.preventDefault();
              e.stopPropagation();
            },
          })}
        <DrawerDialogBase
          open={isOpen}
          onOpenChange={setOpen}
          className={cn({
            "py-10": !paywall,
            "max-w-[700px]": !showUpgrade && !showSuccess,
          })}
        >
          <div data-testid="subscription-modal">
            {paywall === "snooze" && !showSuccess && (
              <SnoozeSubscriptionView
                setShowSuccess={setShowSuccess}
                onOpenChange={setOpen}
              />
            )}
            {paywall === "kitchen_capacity" && !showSuccess && (
              <PrepTimeKitchenSubscriptionView
                setShowSuccess={setShowSuccess}
                onOpenChange={setOpen}
              />
            )}
            {!paywall && showUpgrade && !showSuccess && (
              <UpgradeView
                setShowSuccess={setShowSuccess}
                onOpenChange={setOpen}
              />
            )}
            {!paywall && !manage && !showUpgrade && !showSuccess && (
              <SubscribeDialog
                open={isOpen}
                onOpenChange={setOpen}
                setShowSuccess={setShowSuccess}
              />
            )}
            {showSuccess && (
              <SubscriptionCongratsView
                onContinue={() => {
                  setOpen(false);
                  setTimeout(() => {
                    setShowSuccess(false);
                  }, 1500);
                }}
              />
            )}
            {manage && !showSuccess && (
              <div className="space-y-4">
                <div className="px-16">
                  <p className="font-semibold leading-none tracking-tight text-xl">
                    {t("Manage Subscription")}
                  </p>
                  <p className="text-muted text-sm">
                    {t("Changes will take effect immediately")}
                  </p>
                </div>
                <div className="flex flex-col justify-evenly md:flex-row items-center">
                  <SubscriptionTierView
                    setOpen={setOpen}
                    tier={SUBSCRIPTION_TIERS.per_month_location}
                    setShowSuccess={setShowSuccess}
                  />
                  <SubscriptionTierView
                    setOpen={setOpen}
                    tier={SUBSCRIPTION_TIERS.per_month_location_premium}
                    setShowSuccess={setShowSuccess}
                  />
                </div>
              </div>
            )}
          </div>
        </DrawerDialogBase>
      </>
    );
  }

  return children;
};

function SubscribeDialog({ setShowSuccess, ...props }) {
  const { t } = useTStream("common");

  return (
    <div>
      <div className="my-2 px-10">
        <p className="font-semibold leading-none tracking-tight text-xl">
          {t("Start subscription")}
        </p>
        <p className="text-muted text-sm">
          {t("Start your subscription now to publish your menu")}
        </p>
      </div>
      <div className="px-10 space-y-4">
        <div className="flex flex-col md:flex-row items-center gap-4">
          <SubscriptionTierView
            setOpen={props?.onOpenChange}
            tier={SUBSCRIPTION_TIERS.per_month_location}
            setShowSuccess={setShowSuccess}
          />
          <SubscriptionTierView
            setOpen={props?.onOpenChange}
            tier={SUBSCRIPTION_TIERS.per_month_location_premium}
            setShowSuccess={setShowSuccess}
          />
        </div>
        <EnterpriseSection />
      </div>
    </div>
  );
}

export const UpgradeView = ({
  setShowSuccess,
  onOpenChange,
}: {
  setShowSuccess: (show: boolean) => void;
  onOpenChange: (open: boolean) => void;
}) => {
  const { t } = useTStream("common");
  const { create, isUpdating, priceInfo, allFeatures } = useSubscription();

  const features = allFeatures.filter((feature) =>
    feature.includedIn.includes("premium"),
  );

  const onUpgrade = useCallback(async () => {
    await create(SUBSCRIPTION_TIERS.per_month_location_premium);
    setShowSuccess(true);
  }, [create, setShowSuccess]);

  return (
    <div className="px-10 space-y-4 mt-3">
      <div className="flex flex-col items-center space-y-4 pb-4">
        <div className="rounded-full bg-purple-500 p-4 relative">
          <div className="absolute inset-0 rounded-full bg-purple-500 animate-ping opacity-30"></div>
          <StarIcon className="h-5 w-5 text-white relative z-10" />
        </div>
        <div className="space-y-1 text-center">
          <h2 className="text-xl font-500 tracking-tight">
            {t("Upgrade to Premium plan")}
          </h2>
          <p className="text-muted font-400 text-sm">
            {t("Unlock full potential of Stream features")}
          </p>
        </div>
      </div>

      <Separator />

      <div>
        <div className="space-y-4">
          <h3 className="text-sm font-500">
            {t("Everything in Standard, plus:")}
          </h3>
          <ul className="grid grid-cols-2 gap-3">
            {features
              .filter((feature) => !feature.includedIn.includes("standard"))
              .map((feature) => (
                <li key={feature.name} className="flex items-center">
                  <Check className="mr-2 h-4 w-4 text-emerald-500" />
                  <span className="text-muted text-xs">{feature.name}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <Separator />

      <div className="space-y-1">
        <div className="text-2xl font-500">
          {FormatCents(
            priceInfo?.premium?.unit_amount,
            priceInfo?.premium?.currency,
          )}
        </div>
        <p className="text-sm text-muted">{t("Per location / month")}</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <Button
          onClick={() => onOpenChange(false)}
          variant="outline"
          className="w-full"
          data-dd-action-name={DatadogActionNames.dismiss_upgrade}
        >
          {t("Maybe later")}
        </Button>
        <Button
          className="w-full"
          onClick={onUpgrade}
          loading={isUpdating}
          data-dd-action-name={DatadogActionNames.upgrade_premium}
        >
          {t("Upgrade now")}
        </Button>
      </div>

      <Separator />
      <EnterpriseSection />
    </div>
  );
};
