import { mutations } from "#/lib/atoms/mutations";
import { queries } from "#/lib/atoms/queries";
import { useAtom } from "jotai";

export default function useCancelSubscription() {
  const [{ data: subscriptionResponse }] = useAtom(queries.subscription);
  const [{ mutateAsync: cancelSubscription, isPending: isCancelLoading }] =
    useAtom(mutations.cancelSubscription);

  const cancel = (variables) => cancelSubscription(variables);

  return {
    subscription: subscriptionResponse,
    cancel,
    isCancelLoading,
  };
}
