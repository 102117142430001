import { useMediaQuery } from "#/hooks/use-media-query";
import { useTStream } from "#/hooks/use-t-stream";
import { cn } from "#/lib/utils";
import { Button } from "#/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "#/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "#/ui/drawer";
import { Input } from "#/ui/input";
import clsx from "clsx";
import { TrashIcon } from "lucide-react";
import * as React from "react";
import { twMerge } from "tailwind-merge";
import { useWindowSize } from "usehooks-ts";

export const DrawerDialogBase = ({
  children,
  className,
  closeVisible = true,
  trigger,
  asChild = true,
  ...props
}: {
  open?: boolean;
  className?: string;
  onOpenChange?: (isOpen: boolean) => void;
  children: React.ReactNode;
  closeVisible?: boolean;
  trigger?: React.ReactNode;
  asChild?: boolean;
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const { height } = useWindowSize();
  const { t } = useTStream("common");

  if (isDesktop) {
    return (
      <Dialog {...props}>
        {trigger && <DialogTrigger asChild={asChild}>{trigger}</DialogTrigger>}
        <DialogContent
          closeVisible={closeVisible}
          className={twMerge("p-0 max-h-[95vh] z-50 overflow-auto", className)}
        >
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer {...props}>
      {trigger && <DrawerTrigger asChild={asChild}>{trigger}</DrawerTrigger>}
      <DrawerContent>
        <div
          className="px-4 space-y-4 overflow-auto"
          style={{
            maxHeight: height - height / 3,
          }}
        >
          {children}
        </div>
        {closeVisible && (
          <DrawerFooter className="pt-2 mt-4">
            <DrawerClose asChild>
              <Button variant="outline">{t("Cancel")}</Button>
            </DrawerClose>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export const DrawerDialog = ({
  children,
  title,
  description,
  right,
  classNameHeader,
  trigger,
  closeVisible = true,
  className,
  ...props
}: {
  description?: string | React.ReactNode;
  title: string | React.ReactNode;
  open?: boolean;
  right?: React.ReactNode;
  className?: string;
  classNameHeader?: string;
  closeVisible?: boolean;
  trigger?: React.ReactNode;
  onOpenChange?: (isOpen: boolean) => void;
  children: React.ReactNode;
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const { height } = useWindowSize();
  const { t } = useTStream("common");
  const onClose = React.useCallback(
    () => props?.onOpenChange && props?.onOpenChange(false),
    [],
  );

  if (isDesktop) {
    return (
      <Dialog {...props}>
        {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
        <DialogContent
          closeVisible={closeVisible}
          className={cn("max-h-[95vh] z-50 overflow-auto", className)}
        >
          <DialogHeader
            className={cn(
              "flex w-full items-center flex-row justify-between",
              classNameHeader,
              {
                "pr-3": !!right,
              },
            )}
          >
            <div className="">
              <DialogTitle className="text-xl">{title}</DialogTitle>
              {description && (
                <DialogDescription className="text-left text-muted">
                  {description}
                </DialogDescription>
              )}
            </div>
            {right}
          </DialogHeader>
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer {...props}>
      {trigger && <DrawerTrigger>{trigger}</DrawerTrigger>}
      <DrawerContent>
        <DrawerHeader
          className={cn(
            "text-left flex items-center flex-row justify-between",
            classNameHeader,
            {
              "pr-0": !!right,
            },
          )}
        >
          <div className="text-left">
            <DrawerTitle>{title}</DrawerTitle>
            {description && (
              <DrawerDescription className="text-left text-muted">
                {description}
              </DrawerDescription>
            )}
          </div>
          {right}
        </DrawerHeader>
        <div
          className="px-4 space-y-4 overflow-x-hidden overflow-y-auto"
          style={{
            maxHeight: height - height / 3,
          }}
        >
          {children}
        </div>
        {closeVisible && (
          <DrawerFooter className="pt-2 mt-4">
            <DrawerClose asChild>
              <Button variant="outline" onClick={onClose}>
                {t("Cancel")}
              </Button>
            </DrawerClose>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export const DrawerDialogStacked = ({
  children,
  title,
  description,
  trigger,
  closeVisible = true,
  className,
  asChild = true,
  classNameIcon,
  icon,
  ...props
}: {
  description?: string | React.ReactNode;
  title: string;
  open?: boolean;
  closeVisible?: boolean;
  icon?: React.ReactNode;
  trigger?: React.ReactNode;
  classNameIcon?: string;
  className?: string;
  onOpenChange?: (isOpen: boolean) => void;
  children?: React.ReactNode;
  asChild?: boolean;
}) => {
  const { t } = useTStream("common");
  const isDesktop = useMediaQuery("(min-width: 768px)");

  if (isDesktop) {
    return (
      <Dialog {...props}>
        {trigger && <DialogTrigger asChild={asChild}>{trigger}</DialogTrigger>}
        <DialogContent
          closeVisible={closeVisible}
          className={twMerge("max-h-[95vh] z-50 overflow-auto", className)}
        >
          <DialogHeader className="w-full text-center space-y-4 items-center">
            {icon && (
              <div
                className={cn(
                  "h-16 w-16 mb-2 rounded-full overflow-hidden items-center justify-center flex",
                  classNameIcon,
                )}
              >
                {icon}
              </div>
            )}
            <DialogTitle
              className={clsx("text-center", {
                "mt-10": !icon,
              })}
            >
              {title}
            </DialogTitle>
            {description && (
              <DialogDescription className="text-center text-muted">
                {description}
              </DialogDescription>
            )}
          </DialogHeader>
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer {...props}>
      {trigger && <DrawerTrigger>{trigger}</DrawerTrigger>}
      <DrawerContent>
        <DrawerHeader className="text-left space-x-1 flex items-center">
          {icon && (
            <div className="h-16 w-16 mb-2 border-2 rounded-full overflow-hidden items-center justify-center flex">
              {icon}
            </div>
          )}
          <div className="text-left">
            <DrawerTitle>{title}</DrawerTitle>
            {description && (
              <DrawerDescription className="text-left text-muted">
                {description}
              </DrawerDescription>
            )}
          </div>
        </DrawerHeader>
        <div className="px-2 md:px-4 space-y-4">{children}</div>
        {closeVisible && (
          <DrawerFooter className="pt-2 mt-4">
            <DrawerClose asChild>
              <Button variant="outline">{t("Cancel")}</Button>
            </DrawerClose>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};
export const ConfirmRemoveDrawerDialog = ({
  title,
  message,
  confirmText,
  onConfirm,
  loading,
  icon = (
    <div className="h-full w-full bg-red-100 flex items-center justify-center">
      <TrashIcon className="h-6 w-6 text-destructive" aria-hidden="true" />
    </div>
  ),
  children,
  trigger,
}: {
  trigger: React.ReactNode;
  title: string;
  message: string;
  confirmText?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  loading: boolean;
  onConfirm(): void;
}) => {
  const { t } = useTStream("common");
  const [open, setOpen] = React.useState(false);
  const [confirmInput, setConfirmInput] = React.useState("");

  const beforeConfirm = React.useCallback(async () => {
    await onConfirm();
    setOpen(false);
  }, [onConfirm]);

  const isConfirmDisabled = confirmText
    ? confirmInput.toLowerCase() !== confirmText.toLowerCase()
    : false;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !isConfirmDisabled) {
      beforeConfirm();
    }
  };

  return (
    <DrawerDialogStacked
      icon={icon}
      title={title}
      description={message}
      trigger={trigger}
      onOpenChange={setOpen}
      open={open}
    >
      {children}
      {confirmText && (
        <Input
          type="text"
          value={confirmInput}
          onChange={(e) => setConfirmInput(e.target.value)}
          onKeyDown={handleKeyDown}
          id="confirm-input"
          placeholder={`${t("Type")} "${confirmText}" ${t("to remove")}`}
        />
      )}

      <div className="flex w-full gap-2 md:space-x-4 mt-3 px-5">
        <Button
          onClick={() => setOpen(false)}
          variant="outline"
          className="w-full"
        >
          {t("Cancel")}
        </Button>
        <Button
          onClick={beforeConfirm}
          loading={loading}
          disabled={isConfirmDisabled}
          className="w-full"
        >
          {t("Remove")}
        </Button>
      </div>
    </DrawerDialogStacked>
  );
};
