import { getGlobalObject } from "#/utils/get-global-object.utils";

export const logError = (
  error: Error,
  info: {
    componentStack?: string;
    errorId: string;
    email?: string;
    orgId?: string;
  },
) => {
  const RumGlobal = getGlobalObject<Window>().DD_RUM;
  if (RumGlobal) {
    RumGlobal.addError(error, {
      scope: info?.componentStack,
      errorId: info.errorId,
      email: info.email,
      orgId: info.orgId,
    });
  }
};
