"use client";

import { SnoozePopoverView } from "#/components/menu/menu-item-snooze-popover";
import useSubscription from "#/hooks/data/subscription/use-subscription";
import { useTStream } from "#/hooks/use-t-stream";
import { Button } from "#/ui/button";
import { DateTimePickerForm } from "#/ui/date-time-picker-form";
import { Separator } from "#/ui/separator";
import { FormatCents } from "#/utils/currency.utils";
import { SUBSCRIPTION_TIERS } from "#/utils/subscription.utils";
import { StarIcon } from "@heroicons/react/24/solid";
import { Check } from "lucide-react";
import { useCallback } from "react";

export const SnoozeSubscriptionView = ({
  setShowSuccess,
  onOpenChange,
}: {
  setShowSuccess: (show: boolean) => void;
  onOpenChange: (open: boolean) => void;
}) => {
  const { t } = useTStream("common");
  const { create, isUpdating, priceInfo, allFeatures } = useSubscription();

  const features = allFeatures.filter((feature) =>
    feature.includedIn.includes("premium"),
  );

  const onUpgrade = useCallback(async () => {
    await create(SUBSCRIPTION_TIERS.per_month_location_premium);
    setShowSuccess(true);
  }, [create, setShowSuccess]);

  return (
    <>
      <div className="w-full flex items-center justify-center h-[310px] bg-background">
        <div className="relative">
          <div className="w-[330px] px-3 pt-1 overflow-hidden shadow-xl h-[230px] bg-card rounded-xl">
            <div className="scale-[0.93] translate-y-[-10px]">
              <SnoozePopoverView
                snoozeType="item_family"
                onChangeType={() => {}}
                onUpdateDate={() => {}}
                onRevert={() => {}}
                onSave={() => {}}
                snoozeDate={new Date()}
              />
            </div>
          </div>
          <div className="w-[180px] p-4 items-center justify-center flex flex-col absolute right-[-50px] bottom-[-25px] z-10 h-[100px] bg-card shadow-xl rounded-xl">
            {/* STAR */}
            <div className="relative w-full">
              <div className="rounded-full bg-purple-500 p-2 absolute right-[-30px] top-[-18px]">
                <div className="absolute inset-0 rounded-full bg-purple-500 animate-ping opacity-30"></div>
                <StarIcon className="h-5 w-5 text-white relative z-10" />
              </div>
            </div>

            <div className="gap-2 translate-y-[5px]">
              <p className="text-[12px]">{t("Custom Snooze")}</p>
              <p className="text-[10px] text-muted">
                {t("Choose a custom date and time to snooze your items")}
              </p>
            </div>
            <div className="scale-[0.62]">
              <DateTimePickerForm date={new Date()} onUpdate={() => {}} />
            </div>
          </div>
        </div>
      </div>
      <div className="px-10 space-y-4 pb-4">
        <div className="flex flex-col items-center">
          <div className="space-y-1 text-start">
            <h2 className="text-xl font-500 tracking-tight">
              {t("Smart Menu Management with Advanced Item Snoozing")}
            </h2>
            <p className="text-muted font-400 text-sm">
              {t(
                "Take control of your menu availability with precision timing. Set custom snooze periods for menu items or entire categories, automatically making them available again at your specified date and time. Perfect for seasonal items, special promotions, or managing inventory gaps.",
              )}
            </p>
          </div>
        </div>

        <div>
          <div className="space-y-4">
            <h3 className="text-sm font-500">
              {t("Everything in Standard, plus:")}
            </h3>
            <ul className="grid grid-cols-2 gap-3">
              {features
                .filter((feature) => !feature.includedIn.includes("standard"))
                .map((feature) => (
                  <li key={feature.name} className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-emerald-500" />
                    <span className="text-muted text-xs">{feature.name}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <Separator />

        <div className="space-y-1">
          <div className="text-2xl font-500">
            {FormatCents(
              priceInfo?.premium?.unit_amount,
              priceInfo?.premium?.currency,
            )}
          </div>
          <p className="text-sm text-muted">{t("Per location / month")}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <Button
            onClick={() => onOpenChange(false)}
            variant="outline"
            className="w-full"
          >
            {t("Maybe later")}
          </Button>
          <Button className="w-full" onClick={onUpgrade} loading={isUpdating}>
            {t("Upgrade now")}
          </Button>
        </div>
      </div>
    </>
  );
};
