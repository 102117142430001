import * as React from "react";

import { AIDescriptionInput } from "#/components/ai-description-input";
import { useEnabledAI } from "#/hooks/use-enabled-ai";
import { useTStream } from "#/hooks/use-t-stream";
import { cn } from "#/lib/utils";
import { RevertToPOSButton } from "#/ui/button";
import { Input as DeprecatedInput } from "#/ui/standard-input";
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, title, ...props }, ref) => {
    return (
      <div className="relative">
        <label
          className={cn(
            "bg-card absolute left-[0.9rem] text-muted text-sm font-circular-std font-500 top-[-11px] z-[5]",
          )}
        >
          {title}
        </label>
        <input
          type={type}
          className={cn(
            "flex h-10 w-full focus:ring-0 focus:border-primary rounded-xl border border-border bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed",
            className,
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);
Input.displayName = "Input";

const DescriptionInput = ({
  value,
  before,
  onChange,
  promptData,
  onAIGenerate,
}: {
  value: string;
  before: string;
  onChange: (e: string) => void;
  promptData: any;
  onAIGenerate: (e: boolean) => void;
}) => {
  const aiEnabled = useEnabledAI();
  const { t } = useTStream("common");

  return (
    <>
      {/** @TODO: Change this to be a flag from the POS config, so Generic POS can use it */}
      {aiEnabled ? (
        <AIDescriptionInput
          promptData={promptData}
          value={value}
          onChangeItem={(e) => {
            onAIGenerate(true);
            onChange(e);
          }}
          rightHeader={
            <RevertToPOSButton
              before={before}
              after={value}
              onRevert={() => onChange(before)}
            />
          }
        />
      ) : (
        <DeprecatedInput
          title={t("Description")}
          placeholder={t(
            "eg. Chicken Burger with lettuce, tomato, cheese, and mayo",
          )}
          rootStyles="mt-5 w-full"
          rightHeader={
            <RevertToPOSButton
              before={before}
              after={value}
              onRevert={() => onChange(before)}
            />
          }
          register={{
            value,
            onChange: (e) => {
              onAIGenerate(false);
              onChange(e);
            },
          }}
        />
      )}
    </>
  );
};

export { DescriptionInput, Input };
